import { createSlice } from "@reduxjs/toolkit";

const loginShowSlice = createSlice({
  name: "loginShow",
  initialState: true,
  reducers: {
    setLoginShow: (state, action) => {
      return action.payload;
    },
  },
});

export const { setLoginShow } = loginShowSlice.actions;
export default loginShowSlice.reducer;
