import React, { useEffect, useState, useRef } from "react";
import { toast } from "react-toastify";
import { Table, Pagination } from "antd";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import EditIcon from "@mui/icons-material/Edit";
import AddIcon from "@mui/icons-material/Add";
import {
  alertDetails,
  getMappedJsonColumns,
  getMappedDevices,
  addAlertDetails,
  deleteAlertDetails,
  get_location,
} from "../../utils/api";

import {
  InputLabel,
  OutlinedInput,
  Stack,
  Typography,
  Grid,
  Select,
  Tooltip,
  MenuItem,
  Button,
  IconButton,
  Chip,
  FormHelperText,
  Switch,
} from "@mui/material";
import { Tag } from "antd";
import MainCard from "components/MainCard";

function AlertDetails() {
  const formRef = useRef();
  const [AlertData, setAlertData] = useState();
  const [mappedDevices, setMappedDevices] = useState();
  const [jsonColsData, setjsonColsData] = useState([]);
  const [location_list, set_location_list] = useState([]);
  const selectRef = useRef();

  const [alertDetailsReq, setalertDetailsReq] = useState({
    device: "",
    device_name: "i",
    json_column: "i",
    condition: "i",
    values: "",
    location_id: "i",
  });
  const [all_emails, set_all_emails] = useState([]);
  const [email, set_email] = useState("");
  const [email_error, set_email_error] = useState(false);
  const [email_error_msg, set_email_error_msg] = useState("");
  const handle_email = (e) => {
    set_email(e.target.value);
  };
  const handle_add_email = () => {
    const pattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    if (pattern.test(email)) {
      if (!all_emails.includes(email)) {
        const temp = [...all_emails];
        temp.push(email);
        set_all_emails(temp);
        set_email("");
        set_email_error(false);
      } else {
        set_email_error(true);
        set_email_error_msg("The provided email address is already added");
      }
    } else {
      set_email_error(true);
      set_email_error_msg("Please provide a valid email address.");
    }
  };

  const [enable, set_enable] = useState(true);
  const handle_enable = (e) => {
    set_enable(e.target.checked);
  };

  const conditionsData = ["<", ">", "=", "<=", ">="];

  const [edit_status, set_edit_status] = useState(false);
  const [alert_id, set_alert_id] = useState("");

  const handle_edit = (record) => {
    getMappedDevices(success_mapped_device, {
      location_id: record?.location_id,
    });
    get_MappedJsonColumns(record?.device);
    set_edit_status(true);
    set_alert_id(record?.alert_id);
    const temp = {
      device: record?.device,
      device_name: record?.device_name,
      json_column: record?.json_column,
      condition: record?.condition,
      values: record?.values,
      location_id: record?.location_id,
    };
    setalertDetailsReq(temp);
    set_enable(record?.active);
    set_all_emails(record?.email);
  };

  const handleToggle = (record) => {
    add_Alert_details({
      ...record,
      emails: record?.email,
      active: !record?.active,
      ...(edit_status ? { alert_id: alert_id, update: "update" } : {}),
    });
  };

  const AlertDetailColumns = [
    {
      dataIndex: "device",
      title: "Device Id",
      key: "device",
      width: "10%",
    },
    {
      dataIndex: "device_name",
      title: "Device Name",
      key: "device",
      width: "10%",
    },
    {
      dataIndex: "device_type",
      title: "Device Type",
      key: "device_type",
      width: "10%",
    },
    {
      dataIndex: "json_column",
      title: "Parameter",
      key: "json_column",
      width: "10%",
    },
    {
      dataIndex: "condition",
      title: "Condition",
      key: "condition",
      width: "10%",
    },
    {
      dataIndex: "values",
      title: "Value",
      key: "value",
      width: "10%",
    },
    {
      dataIndex: "email",
      title: "Emails",
      key: "email",
      width: "15%",
      render: (email) => {
        return email?.map((item) => (
          <Chip variant="outlined" size="small" label={item} sx={{ m: 0.5 }} />
        ));
      },
    },
    {
      dataIndex: "active",
      title: "Email Status",
      key: "status",
      width: "10%",
      render: (active, record) => {
        return active ? "Active" : "Inactive";
      },
    },
    {
      title: "Action",
      dataIndex: "Action",
      key: "Action",
      width: "10%",
      align: "center",
      render: (_, record, index) => {
        return (
          <>
            <Stack direction="row" alignItems="center" justifyContent="center">
              <Tooltip title={"Edit"}>
                <IconButton
                  onClick={() => {
                    handle_edit(record);
                  }}
                >
                  <EditIcon style={{ color: "#F9A825" }} />
                </IconButton>
              </Tooltip>
              <Tooltip title={"Delete Alert"}>
                <IconButton
                  onClick={() => {
                    const deleteAlertReq = { alert_id: record?.alert_id };
                    deleteAlert_Details(deleteAlertReq); // delete Alert
                  }}
                >
                  <DeleteForeverIcon style={{ color: "Red" }} />
                </IconButton>
              </Tooltip>
            </Stack>
          </>
        );
      },
    },
  ];
  //####################### Handle Api's######################################################
  const add_Alert_details = async (AlertData) => {
    try {
      await addAlertDetails(AlertData, success_Alert);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const success_Alert = (res) => {
    console.log(res);
    if (res.status === "failed") {
      toast.warning(res.message);
    } else {
      formRef.current.reset();
      toast.success(res.message);
      get_AlertDetails();
      setalertDetailsReq({
        device: "",
        device_name: "i",
        json_column: "i",
        condition: "i",
        values: "",
        location_id: "i",
      });
      set_all_emails([]);
      set_enable(true);
      set_email("");
      set_email_error(false);
      set_edit_status(false);
      setjsonColsData([]);
      setMappedDevices([]);
    }
  };

  const get_AlertDetails = async () => {
    try {
      await alertDetails(success_Alert_details);
      await get_location(success_get_locations);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const get_MappedJsonColumns = async (device_Id) => {
    try {
      await getMappedJsonColumns(device_Id, success_mapped_jsonCols);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const success_get_locations = (res) => {
    if (res.status === "failed") {
      toast.warning(res.message);
    } else {
      set_location_list(res.data.data);
    }
  };

  const success_mapped_device = (res) => {
    if (res.status === "failed") {
      toast.warning(res.message);
    } else {
      setMappedDevices(res.data.data);
      setjsonColsData([]);
    }
  };

  const success_Alert_details = (res) => {
    if (res.status === "failed") {
      toast.warning(res.message);
    } else {
      setAlertData(res.data.data);
    }
  };
  const success_mapped_jsonCols = (res) => {
    if (res.status === "failed") {
      toast.warning(res.message);
    } else {
      setjsonColsData(res.data.data);
    }
  };

  // delete selected Alert
  const deleteAlert_Details = async (alertReq) => {
    try {
      await deleteAlertDetails(alertReq, success_delete_alert_data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const success_delete_alert_data = (res) => {
    if (res.status === "failed") {
      toast.warning(res.message);
    } else {
      toast.success(res.message);
      get_AlertDetails();
    }
  };

  useEffect(() => {
    get_AlertDetails();
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    add_Alert_details({
      ...alertDetailsReq,
      emails: all_emails,
      active: enable,
      ...(edit_status ? { alert_id: alert_id, update: "update" } : {}),
    });
  };

  return (
    <Typography variant="body2" mx={4}>
      <form ref={formRef} onSubmit={handleSubmit}>
        <Grid container spacing={2} columns={12} sx={{ mb: 4 }}>
          <Grid item xs={12} lg={3}>
            <Stack spacing={1}>
              <InputLabel htmlFor="title">Location</InputLabel>
              <Select
                labelId="demo-select-small-label"
                id="demo-select-small"
                ref={selectRef}
                defaultValue="i"
                value={alertDetailsReq["location_id"]}
                label="location"
                required
                disabled={edit_status}
                onChange={(e) => {
                  setalertDetailsReq({
                    ...alertDetailsReq,
                    location_id: e.target.value,
                    device_name:'i',
                    json_column:'i'
                  });
                  setjsonColsData([]);
                  getMappedDevices(success_mapped_device, {
                    location_id: e.target.value,
                  });
                }}
              >
                <MenuItem value="i" disabled>
                  <em> Select Location</em>
                </MenuItem>
                {location_list?.map((type) => (
                  <MenuItem value={type.location_id}>
                    {type.location_name}
                  </MenuItem>
                ))}
              </Select>
            </Stack>
          </Grid>
          <Grid item xs={12} lg={3}>
            <Stack spacing={1}>
              <InputLabel htmlFor="title">Device</InputLabel>
              <Select
                labelId="demo-select-small-label"
                id="demo-select-small"
                ref={selectRef}
                defaultValue="i"
                value={alertDetailsReq["device_name"]}
                label="device"
                required
                onChange={(e) => {
                  const device_id =
                    mappedDevices?.find(
                      (data) => data.device_name === e?.target?.value
                    )?.device || "";
                  get_MappedJsonColumns(device_id);
                  setalertDetailsReq({
                    ...alertDetailsReq,
                    device_name: e.target.value,
                    device: device_id,
                    json_column:'i'
                  });
                }}
              >
                <MenuItem value="i" disabled>
                  <em> Select Device</em>
                </MenuItem>
                {mappedDevices?.map((type) => (
                  <MenuItem value={type.device_name}>
                    {type.device_name}
                  </MenuItem>
                ))}
              </Select>
            </Stack>
          </Grid>
          <Grid item xs={12} lg={3}>
            <Stack spacing={1}>
              <InputLabel htmlFor="title">Parameter</InputLabel>
              <Select
                labelId="demo-select-small-label"
                id="demo-select-small"
                ref={selectRef}
                defaultValue="i"
                value={alertDetailsReq["json_column"]}
                label="device"
                required
                onChange={(e) =>
                  setalertDetailsReq({
                    ...alertDetailsReq,
                    json_column: e.target.value,
                  })
                }
              >
                <MenuItem value="i" disabled>
                  <em> Select Parameter</em>
                </MenuItem>
                {jsonColsData?.map((json) => (
                  <MenuItem value={json.json_column}>
                    {json.json_column}
                  </MenuItem>
                ))}
              </Select>
            </Stack>
          </Grid>

          <Grid item xs={12} lg={3}>
            <Stack spacing={1}>
              <InputLabel htmlFor="title">Condition</InputLabel>
              <Select
                labelId="demo-select-small-label"
                id="demo-select-small"
                value={alertDetailsReq["condition"]}
                label="Alert Type"
                required
                onChange={
                  (e) =>
                    setalertDetailsReq({
                      ...alertDetailsReq,
                      condition: e.target.value,
                    })
                  // (alertDetailsReq.location_id = e.target.value)
                }
              >
                <MenuItem value="i" disabled>
                  <em> Select Condition</em>
                </MenuItem>
                {conditionsData?.map((item) => (
                  <MenuItem value={item}>{item}</MenuItem>
                ))}
              </Select>
            </Stack>
          </Grid>

          <Grid item xs={12} lg={3}>
            <Stack spacing={1}>
              <InputLabel htmlFor="title">Value</InputLabel>
              <OutlinedInput
                id="title"
                type="number"
                step={1.0}
                name="value"
                placeholder="Enter Value"
                fullWidth
                value={alertDetailsReq["values"]}
                required
                onChange={(e) => {
                  // const floatRegex = /^[-+]?[0-9]*\.?[0-9]+([eE][-+]?[0-9]+)?$/;
                  // if (floatRegex.test(e.target.value)) {
                  setalertDetailsReq({
                    ...alertDetailsReq,
                    values: e.target.value,
                  });
                  // }
                }}
              />
            </Stack>
          </Grid>
          <Grid item xs={12} lg={3}>
            <Stack spacing={1}>
              <InputLabel htmlFor="title">Emails</InputLabel>
              <Stack direction="row">
                <OutlinedInput
                  id="title"
                  type="email"
                  name="value"
                  placeholder="Enter Email"
                  fullWidth
                  value={email}
                  onChange={handle_email}
                />
                <Tooltip title="Add Email">
                  <IconButton
                    sx={{ height: "100%" }}
                    disabled={email === ""}
                    onClick={handle_add_email}
                  >
                    <AddIcon color="primary" />
                  </IconButton>
                </Tooltip>
              </Stack>
              {email_error && (
                <FormHelperText error={email_error}>
                  {email_error_msg}
                </FormHelperText>
              )}
            </Stack>
            {all_emails?.map((item, index) => {
              return (
                <Chip
                  sx={{ m: 0.5 }}
                  size="small"
                  label={item}
                  onDelete={() => {
                    const temp = all_emails?.filter((email) => item !== email);
                    set_all_emails(temp);
                  }}
                />
              );
            })}
          </Grid>

          <Grid item xs={12} lg={3}>
            <Stack spacing={1}>
              <InputLabel htmlFor="title">Email Status</InputLabel>
              <Switch
                checked={enable}
                onChange={handle_enable}
                defaultChecked
              />
            </Stack>
          </Grid>

          <Grid item xs={6} lg={3}>
            <Stack
              direction="row"
              justifyContent="end"
              alignItems="end"
              sx={{ height: "100%" }}
            >
              <Button
                size="medium"
                type="reset"
                variant="contained"
                color="primary"
                onClick={() => {
                  setalertDetailsReq({
                    device: "",
                    device_name: "i",
                    json_column: "i",
                    condition: "i",
                    values: "",
                    location_id: "i",
                  });
                  set_all_emails([]);
                  set_enable(true);
                }}
                className="mx-2"
              >
                Reset
              </Button>
              <Button
                size="medium"
                type="submit"
                variant="contained"
                color="primary"
                disabled={
                  all_emails?.length === 0 ||
                  alertDetailsReq["location_id"] === "i" ||
                  alertDetailsReq["condition"] === "i" ||
                  alertDetailsReq["device"] === "i" ||
                  alertDetailsReq["json_column"] === "i" ||
                  !alertDetailsReq["values"]
                }
              >
                {!edit_status ? "Submit" : "Update"}
              </Button>
            </Stack>
          </Grid>
        </Grid>
      </form>
      <div className="mt-2">
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Table
              dataSource={AlertData}
              columns={AlertDetailColumns}
              // scroll={{ x: 940, y: 410 }}
              size="small"
              bordered
              pagination={true}
            />
          </Grid>
        </Grid>
      </div>
    </Typography>
  );
}

export default AlertDetails;
