import { createSlice } from "@reduxjs/toolkit";

const accessControlSlice = createSlice({
  name: "accessControl",
  initialState: "",
  reducers: {
    setaccessControl: (state, action) => {
      return action.payload;
    },
  },
});

export const { setaccessControl } = accessControlSlice.actions;
export default accessControlSlice.reducer;
