import { createSlice } from '@reduxjs/toolkit';

const dashboardValueSlice = createSlice({
  name: 'dashboardValue',
  initialState: 0,
  reducers: {
    setDashboardValue: (state, action) => {
      return action.payload;
    },
  },
});

export const { setDashboardValue } = dashboardValueSlice.actions;
export default dashboardValueSlice.reducer;