import React, { useEffect, useState, useRef } from "react";
import { toast } from "react-toastify";
import { Table, Pagination } from "antd";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import AddIcon from "@mui/icons-material/Add";
import {
  alertDetails,
  getMappedJsonColumns,
  getMappedDevices,
  addAlertDetails,
  deleteAlertDetails,
  get_location,
  email_report,
  get_email_report,
  update_report_detials,
  delete_report_details,
} from "../../utils/api";

import {
  InputLabel,
  OutlinedInput,
  Stack,
  Typography,
  Grid,
  Select,
  Tooltip,
  MenuItem,
  Button,
  IconButton,
  Chip,
  FormHelperText,
  Switch,
} from "@mui/material";
import { Tag } from "antd";
import MainCard from "components/MainCard";
import EditIcon from "@mui/icons-material/Edit";

function Reports() {
  const formRef = useRef();
  const [AlertData, setAlertData] = useState();
  const [mappedDevices, setMappedDevices] = useState();
  const [jsonColsData, setjsonColsData] = useState([]);
  const [location_list, set_location_list] = useState([]);
  const selectRef = useRef();

  const [location_id, set_location_id] = useState("i");
  const [all_emails, set_all_emails] = useState([]);
  const [email, set_email] = useState("");
  const [email_error, set_email_error] = useState(false);
  const [email_error_msg, set_email_error_msg] = useState("");
  const handle_email = (e) => {
    set_email(e.target.value);
  };
  const handle_add_email = () => {
    const pattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    if (pattern.test(email)) {
      if (!all_emails.includes(email)) {
        const temp = [...all_emails];
        temp.push(email);
        set_all_emails(temp);
        set_email("");
        set_email_error(false);
      } else {
        set_email_error(true);
        set_email_error_msg("The provided email address is already added");
      }
    } else {
      set_email_error(true);
      set_email_error_msg("Please provide a valid email address.");
    }
  };

  const [enable, set_enable] = useState(true);
  const handle_enable = (e) => {
    set_enable(e.target.checked);
  };

  const [frequency, set_frequency] = useState("i");
  const [group, set_group] = useState("");

  const [device, set_device] = useState([]);
  const handle_device = (event) => {
    const {
      target: { value },
    } = event;
    console.log(value);
    set_device(typeof value === "string" ? value.split(",") : value);
  };

  const [edit_status, set_edit_status] = useState(false);
  const handle_edit = (record) => {
    getMappedDevices(success_mapped_device, {
      location_id: record?.location_id,
    });
    set_edit_status(true);
    set_group(record?.group);
    set_location_id(record?.location_id);
    set_device(record?.device_id);
    set_all_emails(record?.email);
    set_enable(record?.active);
    set_frequency(record?.frequency);
  };
  const AlertDetailColumns = [
    {
      dataIndex: "group",
      title: "Group",
      key: "group",
      width: "15%",
    },
    {
      dataIndex: "location",
      title: "Location",
      width: "15%",
      key: "location",
    },
    {
      dataIndex: "frequency",
      title: "Frequency",
      width: "15%",
      key: "frequency",
    },
    {
      dataIndex: "device",
      title: "Device Name",
      width: "15%",
      key: "device",
      render: (device) => {
        return device?.map((item) => (
          <Chip variant="outlined" size="small" label={item} sx={{ m: 0.5 }} />
        ));
      },
    },
    {
      dataIndex: "email",
      title: "Emails",
      key: "email",
      width: "10%",
      render: (email) => {
        return email?.map((item) => (
          <Chip variant="outlined" size="small" label={item} sx={{ m: 0.5 }} />
        ));
      },
    },
    {
      dataIndex: "active",
      title: "Status",
      key: "status",
      width: "10%",
      render: (active, record) => {
        return active ? "Active" : "Inactive";
      },
    },
    {
      title: "Action",
      dataIndex: "Action",
      key: "Action",
      width: "2%",
      align: "center",
      render: (_, record, index) => {
        return (
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="center"
            spacing={1}
          >
            <Tooltip title={"Edit"}>
              <IconButton
                onClick={() => {
                  handle_edit(record);
                }}
              >
                <EditIcon style={{ color: "#F9A825" }} />
              </IconButton>
            </Tooltip>
            <Tooltip title={"Delete"}>
              <IconButton
                onClick={() => {
                  deleteAlert_Details({ group: record?.group }); // delete Alert
                }}
              >
                <DeleteForeverIcon style={{ color: "Red" }} />
              </IconButton>
            </Tooltip>
          </Stack>
        );
      },
    },
  ];
  //####################### Handle Api's######################################################
  const add_Alert_details = async (AlertData) => {
    try {
      await email_report(AlertData, success_Alert);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const success_Alert = (res) => {
    console.log(res);
    if (res.status === "failed") {
      toast.warning(res.message);
    } else {
      formRef.current.reset();
      toast.success(res.message);
      get_AlertDetails();
      set_location_id("i");
      set_enable(true);
      set_group("");
      set_frequency("i");
      set_all_emails([]);
      set_device([]);
      set_edit_status(false);
      set_email("");
      set_email_error(false);
    }
  };

  const get_AlertDetails = async () => {
    try {
      await get_email_report(success_Alert_details);
      await get_location(success_get_locations);
      await getMappedDevices(success_mapped_device);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const get_MappedJsonColumns = async (device_Id) => {
    try {
      await getMappedJsonColumns(device_Id, success_mapped_jsonCols);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const success_get_locations = (res) => {
    if (res.status === "failed") {
      toast.warning(res.message);
    } else {
      set_location_list(res.data.data);
    }
  };

  const success_mapped_device = (res) => {
    if (res.status === "failed") {
      toast.warning(res.message);
    } else {
      setMappedDevices(res.data.data);
    }
  };

  const success_Alert_details = (res) => {
    if (res.status === "failed") {
      toast.warning(res.message);
    } else {
      setAlertData(res.data.data);
    }
  };
  const success_mapped_jsonCols = (res) => {
    if (res.status === "failed") {
      toast.warning(res.message);
    } else {
      setjsonColsData(res.data.data);
    }
  };

  // delete selected Alert
  const deleteAlert_Details = async (alertReq) => {
    try {
      await delete_report_details(alertReq, success_delete_alert_data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const success_delete_alert_data = (res) => {
    if (res.status === "failed") {
      toast.warning(res.message);
    } else {
      toast.success(res.message);
      get_AlertDetails();
    }
  };

  useEffect(() => {
    get_AlertDetails();
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    const data = edit_status
      ? {
          update: "update",
          emails: all_emails,
          active: enable,
          frequency: frequency,
          group: group,
          device_id: device,
        }
      : {
          location_id: location_id,
          emails: all_emails,
          active: enable,
          frequency: frequency,
          group: group,
          device_id: device,
        };
    add_Alert_details(data);
  };

  return (
    <Typography variant="body2" mx={4}>
      <form ref={formRef} onSubmit={handleSubmit}>
        <Grid container spacing={2} columns={12} sx={{ mb: 4 }}>
          <Grid item xs={12} lg={3}>
            <Stack spacing={1}>
              <InputLabel htmlFor="title">Group</InputLabel>
              <OutlinedInput
                id="title"
                type="text"
                name="value"
                placeholder="Enter Group"
                fullWidth
                disabled={edit_status}
                value={group}
                required
                onChange={(e) => {
                  set_group(e.target.value);
                }}
              />
            </Stack>
          </Grid>

          <Grid item xs={12} lg={3}>
            <Stack spacing={1}>
              <InputLabel htmlFor="title">Location</InputLabel>
              <Select
                labelId="demo-select-small-label"
                id="demo-select-small"
                disabled={edit_status}
                ref={selectRef}
                defaultValue="i"
                value={location_id}
                label="location"
                MenuProps={{ PaperProps: { sx: { maxHeight: 400 } } }}
                onChange={(e) => {
                  set_location_id(e.target.value);
                  getMappedDevices(success_mapped_device, {
                    location_id: e.target.value,
                  });
                }}
              >
                <MenuItem value="i" disabled>
                  <em> Select Location</em>
                </MenuItem>
                {location_list?.map((type) => (
                  <MenuItem value={type.location_id}>
                    {type.location_name}
                  </MenuItem>
                ))}
              </Select>
            </Stack>
          </Grid>
          <Grid item xs={12} lg={3}>
            <Stack spacing={1}>
              <InputLabel htmlFor="title">Device</InputLabel>
              <Select
                labelId="demo-select-small-label"
                id="demo-select-small"
                ref={selectRef}
                defaultValue="i"
                multiple
                value={device}
                label="device"
                required
                MenuProps={{ PaperProps: { sx: { maxHeight: 400 } } }}
                sx={{
                  height: "fit-content",
                }}
                onChange={(e) => {
                  handle_device(e);
                }}
              >
                <MenuItem value="i" disabled>
                  <em> Select Device</em>
                </MenuItem>
                {mappedDevices?.map((type) => (
                  <MenuItem value={type.device_id}>{type.device_name}</MenuItem>
                ))}
              </Select>
            </Stack>
          </Grid>
          <Grid item xs={12} lg={3}>
            <Stack spacing={1}>
              <InputLabel htmlFor="title">Emails</InputLabel>
              <Stack direction="row">
                <OutlinedInput
                  id="title"
                  type="email"
                  name="value"
                  placeholder="Enter Email"
                  fullWidth
                  value={email}
                  onChange={handle_email}
                />
                <Tooltip title="Add Email">
                  <IconButton
                    sx={{ height: "100%" }}
                    disabled={email === ""}
                    onClick={handle_add_email}
                  >
                    <AddIcon color="primary" />
                  </IconButton>
                </Tooltip>
              </Stack>
              {email_error && (
                <FormHelperText error={email_error}>
                  {email_error_msg}
                </FormHelperText>
              )}
            </Stack>
            {all_emails?.map((item, index) => {
              return (
                <Chip
                  sx={{ m: 0.5 }}
                  size="small"
                  label={item}
                  onDelete={() => {
                    const temp = all_emails?.filter((email) => item !== email);
                    set_all_emails(temp);
                  }}
                />
              );
            })}
          </Grid>

          <Grid item xs={12} lg={3}>
            <Stack spacing={1}>
              <InputLabel htmlFor="title">Frequency</InputLabel>
              <Select
                labelId="demo-select-small-label"
                id="demo-select-small"
                ref={selectRef}
                defaultValue="i"
                value={frequency}
                label="Frequency"
                required
                onChange={(e) => {
                  set_frequency(e.target.value);
                }}
              >
                <MenuItem value="i" disabled>
                  <em> Select Frequency</em>
                </MenuItem>
                <MenuItem value="1 days">Daily</MenuItem>
                <MenuItem value="1 months">Monthly</MenuItem>
              </Select>
            </Stack>
          </Grid>


          <Grid item xs={12} lg={3}>
            <Stack spacing={1}>
              <InputLabel htmlFor="title">Status</InputLabel>
              <Switch
                checked={enable}
                onChange={handle_enable}
                defaultChecked
              />
            </Stack>
          </Grid>

          <Grid item xs={6} lg={6}>
            <Stack
              direction="row"
              justifyContent="end"
              alignItems="end"
              sx={{ height: "100%" }}
            >
              <Button
                size="medium"
                type="reset"
                variant="contained"
                color="primary"
                onClick={() => {
                  set_location_id("i");
                  set_enable(true);
                  set_group("");
                  set_frequency("i");
                  set_all_emails([]);
                  set_device([]);
                }}
                className="mx-2"
              >
                Reset
              </Button>
              <Button
                size="medium"
                type="submit"
                variant="contained"
                color="primary"
                disabled={
                  device?.length === 0 ||
                  all_emails?.length === 0 ||
                  frequency === "i" ||
                  group === ""
                }
              >
                {edit_status ? "Update" : "Submit"}
              </Button>
            </Stack>
          </Grid>
        </Grid>
      </form>
      <div className="mt-2">
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Table
              dataSource={AlertData}
              columns={AlertDetailColumns}
              // scroll={{ x: 940, y: 410 }}
              size="small"
              bordered
              pagination={true}
            />
          </Grid>
        </Grid>
      </div>
    </Typography>
  );
}

export default Reports;
