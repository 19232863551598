import { createSlice } from "@reduxjs/toolkit";

const recordSlice = createSlice({
  name: "record",
  initialState: "",
  reducers: {
    setRecordValue: (state, action) => {
      return action.payload;
    },
  },
});

export const { setRecordValue } = recordSlice.actions;
export default recordSlice.reducer;
