import Axios from "axios";

export const login_api = async (username, password, success) => {
  const response = await fetch(`/api/token/`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      username: username,
      password: password,
    }),
  });
  const text = await response.text();
  if (response.status === 200) {
    console.log("success", JSON.parse(text));
    success(JSON.parse(text));
    // console.log("success")
  } else if (response.status === 500) {
    console.log("Internal Server Error");
  } else {
    console.log("failed", text, response);
    Object.entries(JSON.parse(text))?.forEach(([key, value]) => {
      console.log("fail");
      success("login failed");
    });
  }
};

export const forgot_password_API = async (email, success) => {
  const response = await fetch(`/pm/forgot_password`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      email: email,
    }),
  });
  const text = await response.text();
  if (response.status === 200) {
    console.log("success", JSON.parse(text));
    success(JSON.parse(text));
  } else if (response.status === 500) {
    console.log("Internal Server Error");
  } else {
    console.log("failed", text, response);
  }
};

export const set_password_API = async (set_password_data, success) => {
  const response = await fetch(`/pm/set_password`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(set_password_data),
  });
  const text = await response.text();
  if (response.status === 200) {
    success(JSON.parse(text));
  } else if (response.status === 500) {
    console.log("Internal Server Error");
  } else {
    console.log("failed", text, response);
  }
};

export const get_user_details = async (success) => {
  const token = await localStorage.getItem("Token");

  Axios.get("/userprofile", {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }).then((res) => {
    success(res.data);
  });
};

export const get_user_role = async (success) => {
  const token = await localStorage.getItem("Token");

  Axios.get("/pm/get_user_role", {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }).then((res) => {
    success(res.data);
  });
};

// MACHINE
export const get_all_machineDetails = async (success) => {
  const token = await localStorage.getItem("Token");

  Axios.get("/pm/machine_details", {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }).then((res) => {
    success(res);
  });
};

export const add_Equipment = async (equipmentFormData, success) => {
  const token = await localStorage.getItem("Token");
  const response = await fetch(`/pm/machine_details`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    body: equipmentFormData,
  });
  const text = await response.text();
  if (response.status === 200) {
    console.log("success", JSON.parse(text));
    success(JSON.parse(text));
  } else {
    console.log("failed", text);
    Object.entries(JSON.parse(text)).forEach(([key, value]) => {});
  }
};

export const get_machine_attributes = async (success) => {
  const token = await localStorage.getItem("Token");

  Axios.get("/pm/mchine_attribute", {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }).then((res) => {
    success(res);
  });
};

export const add_Machine_Attribute_Name = async (parameterNameObj, success) => {
  const token = await localStorage.getItem("Token");
  const response = await fetch(`/pm/mchine_attribute`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(parameterNameObj),
  });
  const text = await response.text();
  if (response.status === 200) {
    console.log("success", JSON.parse(text));
    success(JSON.parse(text));
  } else {
    console.log("failed", text);
    Object.entries(JSON.parse(text)).forEach(([key, value]) => {});
  }
};

export const add_Machine_Attribute = async (machine_Attribute, success) => {
  const token = await localStorage.getItem("Token");
  const response = await fetch(`/pm/machine_attribute_value`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(machine_Attribute),
  });
  const text = await response.text();
  if (response.status === 200) {
    console.log("success", JSON.parse(text));
    success(JSON.parse(text));
  } else {
    console.log("failed", text);
    Object.entries(JSON.parse(text)).forEach(([key, value]) => {});
  }
};

// TICKET
export const get_all_ticketDetails = async (
  success,
  search_item,
  page_number
) => {
  const token = await localStorage.getItem("Token");

  Axios.get("/pm/create_ticket", {
    params: { search_item: search_item, page_number: page_number },
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }).then((res) => {
    success(res);
  });
};

export const get_user_all_ticketDetails = async (
  success,
  search_item,
  page_number
) => {
  const token = await localStorage.getItem("Token");

  Axios.get("/pm/get_all_ticket", {
    params: { search_item: search_item, page_number: page_number },
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }).then((res) => {
    success(res);
  });
};

export const get_ticket_approval = async (
  success,
  search_item,
  page_number
) => {
  const token = await localStorage.getItem("Token");

  Axios.get("/pm/ticket_approval", {
    params: { search_item: search_item, page_number: page_number },
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }).then((res) => {
    success(res);
  });
};

export const get_created_by_me = async (success, search_item, page_number) => {
  const token = await localStorage.getItem("Token");

  Axios.get("/pm/ticket_user_created", {
    params: { search_item: search_item, page_number: page_number },
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }).then((res) => {
    success(res);
  });
};

export const get_flow_details = async (success, ticket_id) => {
  const token = await localStorage.getItem("Token");

  Axios.get("/pm/ticket_history", {
    params: { ticket_id: ticket_id },
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }).then((res) => {
    success(res);
  });
};

export const main_dashboard_analysis = async (success) => {
  const token = await localStorage.getItem("Token");

  Axios.get("/pm/dashboard_analysis", {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }).then((res) => {
    success(res);
  });
};

export const count_analysis_ticket_user = async (success) => {
  const token = await localStorage.getItem("Token");

  Axios.get("/pm/analysis_ticket_user", {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }).then((res) => {
    success(res);
  });
};
export const count_analysis_ticket_manager = async (success) => {
  const token = await localStorage.getItem("Token");

  Axios.get("/pm/analysis_for_manager", {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }).then((res) => {
    success(res);
  });
};
export const count_analysis_for_department = async (success) => {
  const token = await localStorage.getItem("Token");

  Axios.get("/pm/analysis_for_department", {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }).then((res) => {
    success(res);
  });
};

export const get_issue_types = async (success, issue_type_name) => {
  const token = await localStorage.getItem("Token");

  Axios.get("/pm/get_issue_type", {
    params: { issue_type: issue_type_name },

    headers: {
      Authorization: `Bearer ${token}`,
    },
  }).then((res) => {
    success(res);
  });
};
export const get_locations = async (success) => {
  const token = await localStorage.getItem("Token");

  Axios.get("/pm/get_locations", {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }).then((res) => {
    success(res);
  });
};
export const get_departments = async (success) => {
  const token = await localStorage.getItem("Token");

  Axios.get("/pm/get_departments", {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }).then((res) => {
    success(res);
  });
};
export const get_prioritys = async (success) => {
  const token = await localStorage.getItem("Token");

  Axios.get("/pm/get_priority_level", {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }).then((res) => {
    success(res);
  });
};

export const add_Ticket = async (ticketFormData, success) => {
  const token = await localStorage.getItem("Token");
  const response = await fetch(`/pm/create_ticket`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    body: ticketFormData,
  });
  const text = await response.text();
  if (response.status === 200) {
    console.log("success", JSON.parse(text));
    success(JSON.parse(text));
  } else {
    console.log("failed", text);
    Object.entries(JSON.parse(text)).forEach(([key, value]) => {});
  }
};

export const get_employee_working_status = async (
  success,
  search_item,
  page_number
) => {
  const token = await localStorage.getItem("Token");

  Axios.get("/pm/employee_working_status", {
    params: { search_item: search_item, page_number: page_number },
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }).then((res) => {
    success(res);
  });
};
export const get_employee_work_status = async (success) => {
  const token = await localStorage.getItem("Token");

  Axios.get("/pm/get_employee_work_status", {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }).then((res) => {
    success(res);
  });
};

export const add_employee_working_status = async (statusFormData, success) => {
  const token = await localStorage.getItem("Token");
  const response = await fetch(`/pm/employee_working_status`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    body: statusFormData,
  });
  const text = await response.text();
  if (response.status === 200) {
    console.log("success", JSON.parse(text));
    success(JSON.parse(text));
  } else {
    console.log("failed", text);
  }
};

export const update_ticket_status = async (
  ticket_id_to_be_updated,
  success
) => {
  const token = await localStorage.getItem("Token");
  const response = await fetch(`/pm/update_ticket_status`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
      // Accept: "application/json",
      // "Content-Type": "application/json",
    },
    body: ticket_id_to_be_updated,
  });
  const text = await response.text();
  if (response.status === 200) {
    console.log("success", JSON.parse(text));
    success(JSON.parse(text));
  } else {
    console.log("failed", text);
  }
};

export const update_workflow_engin = async (workflow_tobe_updated, success) => {
  const token = await localStorage.getItem("Token");
  const response = await fetch(`/pm/workflow_engin`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    body: workflow_tobe_updated,
  });
  const text = await response.text();
  if (response.status === 200) {
    console.log("success", JSON.parse(text));
    success(JSON.parse(text));
  } else {
    console.log("failed", text);
  }
};

export const get_employees_for_assign = async (success) => {
  const token = await localStorage.getItem("Token");

  Axios.get("/pm/get_employee_assign", {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }).then((res) => {
    success(res);
  });
};
export const get_department_approval_data = async (
  success,
  search_item,
  page_number
) => {
  const token = await localStorage.getItem("Token");

  Axios.get("/pm/department_approval_data", {
    params: { search_item: search_item, page_number: page_number },
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }).then((res) => {
    success(res);
  });
};

export const add_issue_type = async (issueType, success) => {
  const token = await localStorage.getItem("Token");
  const response = await fetch(`/pm/add_issue_type`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: issueType,
  });
  const text = await response.text();
  if (response.status === 200) {
    success(JSON.parse(text));
  } else {
    console.log("failed", text);
  }
};

export const get_roles = async (success) => {
  const token = await localStorage.getItem("Token");

  Axios.get("/pm/get_roles", {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }).then((res) => {
    success(res);
  });
};

export const get_roles_config = async (success) => {
  const token = await localStorage.getItem("Token");

  Axios.get("/pm/get_role_config", {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }).then((res) => {
    success(res);
  });
};

export const get_designations = async (success) => {
  const token = await localStorage.getItem("Token");

  Axios.get("/pm/get_designation", {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }).then((res) => {
    success(res);
  });
};

export const get_employee = async (success) => {
  const token = await localStorage.getItem("Token");

  Axios.get("/pm/add_employee_user", {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }).then((res) => {
    success(res);
  });
};
export const get_instruction_status = async (success, ticket_id) => {
  const token = await localStorage.getItem("Token");

  Axios.get("/pm/get_instruction_status", {
    params: { ticket_id: ticket_id },
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }).then((res) => {
    success(res);
  });
};

export const reopen_ticket = async (reopen_formdata, success) => {
  const token = await localStorage.getItem("Token");
  const response = await fetch(`/pm/ticket_reopen`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    body: reopen_formdata,
  });
  const text = await response.text();
  if (response.status === 200) {
    console.log("success", JSON.parse(text));
    success(JSON.parse(text));
  } else {
    console.log("failed", text);
  }
};
export const return_ticket = async (return_formdata, success) => {
  const token = await localStorage.getItem("Token");
  const response = await fetch(`/pm/return_ticket`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    body: return_formdata,
  });
  const text = await response.text();
  if (response.status === 200) {
    console.log("success", JSON.parse(text));
    success(JSON.parse(text));
  } else {
    console.log("failed", text);
  }
};

export const ticket_workflow_creation = async (ticketFlow, success) => {
  const token = await localStorage.getItem("Token");
  const response = await fetch(`/pm/ticket_workflow_creation`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      ticket_flow: ticketFlow,
    }),
  });
  const text = await response.text();
  if (response.status === 200) {
    console.log("success", JSON.parse(text));
    success(JSON.parse(text));
  } else {
    console.log("failed", text);
  }
};

export const get_ticket_workflow_creation = async (success, issue_type_id) => {
  const token = await localStorage.getItem("Token");

  Axios.get("/pm/ticket_workflow_creation", {
    params: { issue_type_id: issue_type_id },
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }).then((res) => {
    success(res.data);
  });
};

export const issue_type_dept_config = async (issueTypeDeptConfig, success) => {
  const token = await localStorage.getItem("Token");
  const response = await fetch(`/pm/add_issue_depart`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(issueTypeDeptConfig),
  });
  const text = await response.text();
  if (response.status === 200) {
    console.log("success", JSON.parse(text));
    success(JSON.parse(text));
  } else {
    console.log("failed", text);
  }
};

export const get_issue_type_dept = async (success) => {
  const token = await localStorage.getItem("Token");

  Axios.get("/pm/add_issue_depart", {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }).then((res) => {
    success(res.data);
  });
};

export const add_employee_user = async (user_data, success) => {
  const token = await localStorage.getItem("Token");
  const response = await fetch(`/pm/add_employee_user`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(user_data),
  });
  const text = await response.text();
  if (response.status === 200) {
    console.log("success", JSON.parse(text));
    success(JSON.parse(text));
  } else {
    console.log("failed", text);
  }
};

export const get_employee_user_list = async (
  department_id,
  designation_id,
  success
) => {
  const token = await localStorage.getItem("Token");

  const params = department_id
    ? { department_id: department_id }
    : designation_id
    ? { designation_id: designation_id }
    : {};

  Axios.get("/pm/add_employee_user", {
    params: params,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }).then((res) => {
    success(res.data);
  });
};

export const get_access_list_admin = async (success) => {
  const token = await localStorage.getItem("Token");

  Axios.get("/pm/add_access", {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }).then((res) => {
    success(res.data);
  });
};

export const update_access_list_admin = async (
  access_list_to_be_updated,
  success
) => {
  const token = await localStorage.getItem("Token");
  const response = await fetch(`/pm/add_access`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ data: access_list_to_be_updated }),
  });
  const text = await response.text();
  if (response.status === 200) {
    console.log("success", JSON.parse(text));
    success(JSON.parse(text));
  } else {
    console.log("failed", text);
  }
};

export const get_employee_access_list = async (success) => {
  const token = await localStorage.getItem("Token");

  Axios.get("/pm/get_employee_access_list", {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }).then((res) => {
    success(res.data.data);
  });
};

export const add_department = async (department, success) => {
  const token = await localStorage.getItem("Token");
  const response = await fetch(`/pm/department_config`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(department),
  });
  const text = await response.text();
  if (response.status === 200) {
    console.log("success", JSON.parse(text));
    success(JSON.parse(text));
  } else {
    console.log("failed", text);
  }
};

export const add_location = async (location, success) => {
  const token = await localStorage.getItem("Token");
  const response = await fetch(`/pm/location_config`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(location),
  });
  const text = await response.text();
  if (response.status === 200) {
    console.log("success", JSON.parse(text));
    success(JSON.parse(text));
  } else {
    console.log("failed", text);
  }
};

export const add_role = async (role, success) => {
  const token = await localStorage.getItem("Token");
  const response = await fetch(`/pm/add_role`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(role),
  });
  const text = await response.text();
  if (response.status === 200) {
    console.log("success", JSON.parse(text));
    success(JSON.parse(text));
  } else {
    console.log("failed", text);
  }
};

export const get_employee_role_config = async (success, role_id) => {
  const token = await localStorage.getItem("Token");

  Axios.get("/pm/add_employee_role_config", {
    params: { role_id: role_id },
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }).then((res) => {
    success(res);
  });
};
export const get_employee_role_config1 = async (success, employee_id) => {
  const token = await localStorage.getItem("Token");

  Axios.get("/pm/add_employee_role_config", {
    params: { employee_id: employee_id },
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }).then((res) => {
    success(res);
  });
};

export const add_role_emp_config = async (role_emp_config, success) => {
  const token = await localStorage.getItem("Token");
  const response = await fetch(`/pm/add_employee_role_config`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({ data: [role_emp_config] }),
  });
  const text = await response.text();
  if (response.status === 200) {
    success(JSON.parse(text));
  } else {
    console.log("failed", text);
  }
};

export const add_location_group = async (group_name, success) => {
  const token = await localStorage.getItem("Token");
  const response = await fetch(`/pm/add_group`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      group: group_name,
    }),
  });
  const text = await response.text();
  if (response.status === 200) {
    success(JSON.parse(text));
  } else {
    console.log("failed", text);
  }
};

export const get_location_groups = async (success) => {
  const token = await localStorage.getItem("Token");

  Axios.get("/pm/get_group", {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }).then((res) => {
    success(res);
  });
};

export const get_Columns_name = async (success) => {
  const token = await localStorage.getItem("Token");

  Axios.get("/column_details", {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }).then((res) => {
    success(res);
  });
};

// export const get_Columns_name_for_edit = async () => {
//   const token = await localStorage.getItem("Token");

//   Axios.get("/column_details", {
//     headers: {
//       Authorization: `Bearer ${token}`,
//     },
//   }).then((res) => {
//     if (res.status === 200) {
//     return res;
//     } else {
//       throw new Error(`http error! Status: ${res.status}` )
//     }
//   });
// };

export const get_Columns_name_for_edit = async () => {
  try {
    const token = await localStorage.getItem("Token");

    const response = await Axios.get("/column_details", {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    if (response.status === 200) {
      return response.data; // Assuming you want to return the data from the response
    } else {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }
  } catch (error) {
    throw new Error(`An error occurred: ${error.message}`);
  }
};

export const get_Device_type = async (success) => {
  const token = await localStorage.getItem("Token");

  Axios.get("/device_type_data", {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }).then((res) => {
    success(res);
  });
};

export const get_filtered_Device_type = async (success) => {
  const token = await localStorage.getItem("Token");

  Axios.get("/filter_device_type_not_in", {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }).then((res) => {
    success(res);
  });
};

export const get_location = async (success) => {
  const token = await localStorage.getItem("Token");

  Axios.get("/location_details", {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }).then((res) => {
    success(res);
  });
};

export const getDeviceDetails = async (success) => {
  const token = await localStorage.getItem("Token");

  Axios.get("/device_details", {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }).then((res) => {
    success(res);
  });
};

export const add_column_mapping = async (mappingdata, success) => {
  const token = await localStorage.getItem("Token");
  const response = await fetch(`/mapping_details`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(mappingdata),
  });
  const text = await response.text();
  if (response.status === 200) {
    console.log("success", JSON.parse(text));
    success(JSON.parse(text));
  } else {
    console.log("failed", text);
  }
};

export const update_column_mapping = async (mappingdata, success) => {
  const token = await localStorage.getItem("Token");
  const response = await fetch(`/update_mapping_details`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(mappingdata),
  });
  const text = await response.text();
  if (response.status === 200) {
    console.log("success", JSON.parse(text));
    success(JSON.parse(text));
  } else {
    console.log("failed", text);
  }
};

export const add_deviceDetails = async (deviceData, success) => {
  const token = await localStorage.getItem("Token");
  const response = await fetch(`/device_details`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(deviceData),
  });
  const text = await response.text();
  if (response.status === 200) {
    console.log("success", JSON.parse(text));
    success(JSON.parse(text));
  } else {
    console.log("failed", text);
  }
};

export const update_deviceDetails = async (deviceData, success) => {
  const token = await localStorage.getItem("Token");
  const response = await fetch(`/update_device_details`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(deviceData),
  });
  const text = await response.text();
  if (response.status === 200) {
    console.log("success", JSON.parse(text));
    success(JSON.parse(text));
  } else {
    console.log("failed", text);
  }
};

export const add_deviceType = async (deviceData, success) => {
  const token = await localStorage.getItem("Token");
  const response = await fetch(`/device_type_data`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(deviceData),
  });
  const text = await response.text();
  if (response.status === 200) {
    console.log("success", JSON.parse(text));
    success(JSON.parse(text));
  } else {
    console.log("failed", text);
  }
};
export const add_deviceLocation = async (deviceData, success) => {
  const token = await localStorage.getItem("Token");
  const response = await fetch(`/location_details`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(deviceData),
  });
  const text = await response.text();
  if (response.status === 200) {
    console.log("success", JSON.parse(text));
    success(JSON.parse(text));
  } else {
    console.log("failed", text);
  }
};

export const genarate_pdf_for_ticket = async (success, ticket_id) => {
  const token = await localStorage.getItem("Token");
  const response = await fetch(`/genarate_pdf_for_ticket`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      ticket_id: ticket_id,
    }),
  });
  const text = await response.text();
  if (response.status === 200) {
    success(JSON.parse(text));
  } else {
    console.log("failed", text);
  }
};

export const get_mapped_deviceTypes = async (success) => {
  const token = await localStorage.getItem("Token");

  Axios.get("/filter_device_type_in", {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }).then((res) => {
    success(res);
  });
};

export const mapping_details_byId = async (type_id, success) => {
  const token = await localStorage.getItem("Token");

  Axios.get("/fetch_mapping_details_by_device_id", {
    params: { device_type_id: type_id },
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }).then((res) => {
    success(res);
  });
};

export const getMappedJsonColumns = async (device_id, success) => {
  const token = await localStorage.getItem("Token");

  Axios.get("/get_json_columns", {
    params: { device_id: device_id },
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }).then((res) => {
    success(res);
  });
};

export const deleteAlertDetails = async (deleteReq, success) => {
  const token = await localStorage.getItem("Token");
  const response = await fetch(`/delete_alert_details`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(deleteReq),
  });
  const text = await response.text();
  if (response.status === 200) {
    console.log("success", JSON.parse(text));
    success(JSON.parse(text));
  } else {
    console.log("failed", text);
  }
};

export const deleteEventDetails = async (deleteReq, success) => {
  const token = await localStorage.getItem("Token");
  const response = await fetch(`/delete_event_details`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(deleteReq),
  });
  const text = await response.text();
  if (response.status === 200) {
    console.log("success", JSON.parse(text));
    success(JSON.parse(text));
  } else {
    console.log("failed", text);
  }
};

export const delete_report_details = async (deleteReq, success) => {
  const token = await localStorage.getItem("Token");
  const response = await fetch(`/delete_report_details`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(deleteReq),
  });
  const text = await response.text();
  if (response.status === 200) {
    console.log("success", JSON.parse(text));
    success(JSON.parse(text));
  } else {
    console.log("failed", text);
  }
};

export const alertDetails = async (success) => {
  const token = await localStorage.getItem("Token");

  Axios.get("/alert_details", {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }).then((res) => {
    success(res);
  });
};

export const get_email_report = async (success) => {
  const token = await localStorage.getItem("Token");

  Axios.get("/email_report", {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }).then((res) => {
    success(res);
  });
};

export const getMappedDevices = async (success, params) => {
  const token = await localStorage.getItem("Token");

  Axios.get("/get_mapped_device", {
    params: params,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }).then((res) => {
    success(res);
  });
};

export const addAlertDetails = async (alertDetails, success) => {
  const token = await localStorage.getItem("Token");
  const response = await fetch(`/alert_details`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(alertDetails),
  });
  const text = await response.text();
  if (response.status === 200) {
    console.log("success", JSON.parse(text));
    success(JSON.parse(text));
  } else {
    console.log("failed", text);
  }
};


export const add_Event_Details = async (alertDetails, success) => {
  const token = await localStorage.getItem("Token");
  const response = await fetch(`/add_event_details`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(alertDetails),
  });
  const text = await response.text();
  if (response.status === 200) {
    console.log("success", JSON.parse(text));
    success(JSON.parse(text));
  } else {
    console.log("failed", text);
  }
};

export const get_Event_Details = async (success) => {
  const token = await localStorage.getItem("Token");

  Axios.get("/get_event_details", {
   // params: {device:params},
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }).then((res) => {
    success(res);
  });
};

export const email_report = async (report_details, success) => {
  const token = await localStorage.getItem("Token");
  const response = await fetch(`/email_report`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(report_details),
  });
  const text = await response.text();
  if (response.status === 200) {
    console.log("success", JSON.parse(text));
    success(JSON.parse(text));
  } else {
    console.log("failed", text);
  }
};
export const update_report_detials = async (report_details, success) => {
  const token = await localStorage.getItem("Token");
  const response = await fetch(`/update_report_detials`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(report_details),
  });
  const text = await response.text();
  if (response.status === 200) {
    console.log("success", JSON.parse(text));
    success(JSON.parse(text));
  } else {
    console.log("failed", text);
  }
};

export const delete_Column_Mapping = async (device_type_id, success) => {
  const token = await localStorage.getItem("Token");
  const response = await fetch(`/delete_mapping_details`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(device_type_id),
  });
  const text = await response.text();
  if (response.status === 200) {
    console.log("success", JSON.parse(text));
    success(JSON.parse(text));
  } else {
    console.log("failed", text);
  }
};

export const delete_device_details = async (device_id, success) => {
  const token = await localStorage.getItem("Token");
  const response = await fetch(`/delete_device_details`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({ device_id: device_id }),
  });
  const text = await response.text();
  if (response.status === 200) {
    console.log("success", JSON.parse(text));
    success(JSON.parse(text));
  } else {
    console.log("failed", text);
  }
};
