import PropTypes from "prop-types";

// material-ui
import { useTheme } from "@mui/material/styles";
import { Stack } from "@mui/material";

// project import
import DrawerHeaderStyled from "./DrawerHeaderStyled";
// import Logo from 'components/Logo';
import Logo from "../../../../assets/images/CompanyLogo11.jpg";

// ==============================|| DRAWER HEADER ||============================== //

const DrawerHeader = ({ open }) => {
  const theme = useTheme();

  return (
    // only available in paid version
    <DrawerHeaderStyled
      theme={theme}
      open={open}
      style={{
        backgroundColor: "#250630",
        height: "130px",
      }}
    >
      <Stack direction="row" justifyContent="center"  >
        <img
          style={{
            height: "90px",
            "mix-blend-mode": "lighten",
            //  margin: '50px 0'
          }}
          src={Logo}
          alt="Company Logo"
        />
      </Stack>
    </DrawerHeaderStyled>
  );
};

DrawerHeader.propTypes = {
  open: PropTypes.bool,
};

export default DrawerHeader;
