// types
import { createSlice } from "@reduxjs/toolkit";

// initial state
const initialState = {
  userModel: {
    isLogin: false,
  },
};

// ==============================|| SLICE - MENU ||============================== //

const auth = createSlice({
  name: "auth",
  initialState,
  reducers: {
    LOGIN_AUTH: (state, action) => {
      state.userModel.isLogin = true;
    },
    LOGOUT_AUTH: (state, action) => {
      state.userModel.isLogin = false;
    },
  },
});

export default auth.reducer;

export const { LOGIN_AUTH, LOGOUT_AUTH } = auth.actions;
