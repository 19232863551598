import { lazy } from "react";

// project import
import Loadable from "components/Loadable";
import MainLayout from "layout/MainLayout";
import EditMapping from "pages/Tata/EditMapping";
import AlertsAndReports from "pages/Tata/AlertsAndReports";

// render - dashboard
const DashboardDefault = Loadable(lazy(() => import("pages/dashboard")));

// const MachineDetails = Loadable(lazy(() => import('pages/Preventive-maintenance/machine-details/MachineDetails')));

const TicketWorkFlow = Loadable(
  lazy(() =>
    import("pages/Preventive-maintenance/configuration/TicketWorkFlow")
  )
);
const IssueTypeDepartment = Loadable(
  lazy(() =>
    import("pages/Preventive-maintenance/configuration/IssueTypeDepartment")
  )
);
const UserConfig = Loadable(
  lazy(() => import("pages/Preventive-maintenance/configuration/UserConfig"))
);

const AccessControl = Loadable(
  lazy(() => import("pages/Preventive-maintenance/configuration/AccessControl"))
);
const AddDepartment = Loadable(
  lazy(() => import("pages/Preventive-maintenance/configuration/AddDepartment"))
);
const AddLocation = Loadable(
  lazy(() => import("pages/Preventive-maintenance/configuration/AddLocation"))
);
const AddRole = Loadable(
  lazy(() => import("pages/Preventive-maintenance/configuration/AddRole"))
);
const EmpRoleConfig = Loadable(
  lazy(() =>
    import("pages/Preventive-maintenance/configuration/RoleEmployeeConfig")
  )
);

const LocationGroup = Loadable(
  lazy(() => import("pages/Preventive-maintenance/configuration/LocationGroup"))
);

// render - sample page
const SamplePage = Loadable(lazy(() => import("pages/Tata/Sample")));
const AddDeviceType = Loadable(lazy(() => import("pages/Tata/AddDeviceType")));
const AddDevicedetails = Loadable(
  lazy(() => import("pages/Tata/AddDeviceDetails"))
);
const AddDeviceLocation = Loadable(
  lazy(() => import("pages/Tata/AddLocation"))
);

const MappingPage = Loadable(lazy(() => import("pages/Tata/MappingPage")));
const AlertDetails = Loadable(lazy(() => import("pages/Tata/AlertDetails")));

const UserProfile = Loadable(lazy(() => import("components/UserProfile")));

// const Name = Loadable(lazy(() => import('path')));

// render - utilities
// const Typography = Loadable(lazy(() => import('pages/components-overview/Typography')));
// const Color = Loadable(lazy(() => import('pages/components-overview/Color')));
// const Shadow = Loadable(lazy(() => import('pages/components-overview/Shadow')));
// const AntIcons = Loadable(lazy(() => import('pages/components-overview/AntIcons')));

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
  path: "/",
  element: <MainLayout />,
  children: [
    {
      path: "/",
      element: <DashboardDefault />,
    },
    {
      path: "/dashboard",
      element: <DashboardDefault />,
    },
    {
      path: "/ticket-work-flow",
      element: <TicketWorkFlow />,
    },
    {
      path: "/issueTypeDepartment",
      element: <IssueTypeDepartment />,
    },
    {
      path: "/user-configuration",
      element: <UserConfig />,
    },
    {
      path: "/mapping-column",
      element: <SamplePage />,
    },
    {
      path: "/access-control",
      element: <AccessControl />,
    },
    {
      path: "/add-department",
      element: <AddDepartment />,
    },
    {
      path: "/add-location",
      element: <AddLocation />,
    },
    {
      path: "/add-role",
      element: <AddRole />,
    },
    {
      path: "/role-configuration",
      element: <EmpRoleConfig />,
    },
    {
      path: "/location-group",
      element: <LocationGroup />,
    },
    {
      path: "/add-deviceType",
      element: <AddDeviceType />,
    },
    {
      path: "/add-deviceDetails",
      element: <AddDevicedetails />,
    },
    {
      path: "/add-deviceLocation",
      element: <AddDeviceLocation />,
    },
    {
      path: "/mapping-details",
      element: <MappingPage />,
    },
    {
      path: "/user-profile",
      element: <UserProfile />,
    },
    {
      path: "/alerts-reports",
      element: <AlertsAndReports />,
    },
    
  ],
};

export default MainRoutes;
