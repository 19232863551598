import React, { useState, useEffect } from "react";

// import { DashboardOutlined } from '@ant-design/icons';
import DashboardOutlinedIcon from "@mui/icons-material/DashboardOutlined";
import CasinoOutlinedIcon from "@mui/icons-material/CasinoOutlined";
import ConfirmationNumberOutlinedIcon from "@mui/icons-material/ConfirmationNumberOutlined";
import CategoryOutlinedIcon from "@mui/icons-material/CategoryOutlined";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import PermDataSettingIcon from "@mui/icons-material/PermDataSetting";
import AssignmentTurnedInOutlinedIcon from "@mui/icons-material/AssignmentTurnedInOutlined";
import SchemaOutlinedIcon from "@mui/icons-material/SchemaOutlined";

// icons
const icons = {
  DashboardOutlinedIcon,
  SchemaOutlinedIcon,
  CasinoOutlinedIcon,
  ConfirmationNumberOutlinedIcon,
  CategoryOutlinedIcon,
  PersonOutlineIcon,
  SettingsOutlinedIcon,
  PermDataSettingIcon,
  AssignmentTurnedInOutlinedIcon,
};

// ==============================|| MENU ITEMS ||============================== //

function Dashboard() {
  return {
    id: "DashBoard",
    title: "DashBoard",
    type: "group",
    children: [
      {
        id: "DashBoard",
        title: "DashBoard",
        type: "item",
        icon: icons.CasinoOutlinedIcon,
        url: "https://dashboard.tplgps.in/login/",
        target: "_blank", // Open in a new tab
        breadcrumbs: true,
      },
    ],
  };
}

export default Dashboard;
