import MainCard from "components/MainCard";
import React, { useState } from "react";
import {
  InputLabel,
  OutlinedInput,
  Stack,
  Typography,
  Grid,
  Select,
  Tooltip,
  MenuItem,
  Button,
  IconButton,
  Chip,
  FormHelperText,
  Switch,
} from "@mui/material";
import { Tabs } from "antd";
import AlertDetails from "./AlertDetails";
import EventDetails from "./EventDetails";
import Reports from "./Reports";

function AlertsAndReports() {
  const [tab_value, set_tab_value] = useState("1");
  const handle_tab_change = (tab) => {
    set_tab_value(tab);
  };
  return (
    <MainCard>
      <Tabs
        activeKey={tab_value}
        onChange={handle_tab_change}
        moreIcon
        items={[
          {
            label: "Alerts",
            key: "1",
            children: <AlertDetails />,
          },
          {
            label: "Reports",
            key: "2",
            children: <Reports />,
          },
          {
            label: "Events",
            key: "3",
            children: <EventDetails />,
          },
        ]}
      />
    </MainCard>
  );
}

export default AlertsAndReports;
