import { createSlice } from "@reduxjs/toolkit";

const setpasswordShowSlice = createSlice({
  name: "setpasswordShow",
  initialState: false,
  reducers: {
    setsetpasswordShow: (state, action) => {
      return action.payload;
    },
  },
});

export const { setsetpasswordShow } = setpasswordShowSlice.actions;
export default setpasswordShowSlice.reducer;
