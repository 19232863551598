import { createSlice } from "@reduxjs/toolkit";

const otpShowSlice = createSlice({
  name: "otpShow",
  initialState: false,
  reducers: {
    setOtpShow: (state, action) => {
      return action.payload;
    },
  },
});

export const { setOtpShow } = otpShowSlice.actions;
export default otpShowSlice.reducer;
