// third-party
import { combineReducers } from "redux";

// project import
import menu from "./menu";
import auth from "./auth";
import search from "./search";
import dashboardValue from "./dashboardValue";
import recordView from "./recordView";
import accessControl from "./accessControl";
import maindashboard from "./maindashboard";
import loginShow from "./loginShow";
import otpShow from "./otpValueShow";
import forgotPasswordShow from "./forgotPassword";
import setpasswordShow from "./setpasswordshow";

// ==============================|| COMBINE REDUCERS ||============================== //

const reducers = combineReducers({
  menu,
  auth,
  search,
  dashboardValue,
  recordView,
  forgotPasswordShow,
  accessControl,
  maindashboard,
  otpShow,
  loginShow,
  setpasswordShow
});

export default reducers;
