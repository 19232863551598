import React, { lazy } from "react";

import MainCard from "components/MainCard";
import Autocomplete from "@mui/material/Autocomplete";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { Modal } from "antd";
import {
  get_Columns_name,
  get_mapped_deviceTypes,
  update_column_mapping,
  mapping_details_byId,
  get_Columns_name_for_edit,
} from "../../utils/api";
import {
  Button,
  Typography,
  Tooltip,
  Stack,
  Grid,
  CardHeader,
  InputLabel,
} from "@mui/material";
import TextField from "@mui/material/TextField";
import { useNavigate } from "react-router";
import { useEffect, useState, useRef } from "react";
import { toast } from "react-toastify";

const EditMapping = (props) => {
  const navigate = useNavigate();
  const [jsonDataKeysvalues, setjsonDataKeysvalues] = useState();
  const [dropdownlables, setDropdownLabels] = useState([]);
  const [sourceData1, setSourceData1] = useState();
  const [avoidColumnsList, setAvoidColumnsList] = useState([]);
  const [deviceTypes, setdeviceTypes] = useState();
  const formRef = useRef();
  const [visible, setVisible] = useState(false);
  const [sourceData, setSourceData] = useState([]);
  const noneJson = {
    label: "none",
    value: "",
  };
  const [mappedData, setMappedData] = useState([]);
  const [jsonData, setJsonData] = useState();
  //get device type from props
  const { deviceTypeId } = props;

  const reloadPageWithDelay = (delayTime) => {
    setTimeout(() => {
      window.location.reload();
    }, delayTime);
  };

  const mappingrequest = {
    data: [],
  };

  const success_column_names = (res) => {
    setSourceData(res.data.data);
    get_mapped_data(deviceTypeId); // call get already mapped data for device type id
  };

  // const get_Columns_names_forEditMapping = () => {
  //   // Implement your API call logic and return the Promise
  //   return fetch('/column_details') // Replace with your API endpoint
  //     .then(response => {
  //       if (!response.ok) {
  //         throw new Error(`HTTP error! Status: ${response.status}`);
  //       }
  //       return response.json();
  //     });
  // };

  //Api for column names
  const get_column_names = async () => {
    try {
      await get_Columns_name(success_column_names);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const success_device_types = (res) => {
    setdeviceTypes(res.data.data);
  };

  const get_DeviceTypes = async () => {
    try {
      await get_mapped_deviceTypes(success_device_types);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };



  // get mapped data by devise id
  const get_mapped_data = async (dType_id) => {
    try {
      await mapping_details_byId(dType_id, success_mapped_data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const success_mapped_data = async (res) => {
    if (res.status === "failed") {
      toast.warning(res.message);
    } else {
      try {
      const resData = res?.data;
      setMappedData(resData.data);
      setJsonData(resData.json_data);
      setjsonDataKeysvalues(resData.json_data);
      
      const labels = Object.keys(resData?.json_data)?.map((key) => ({
        label: key,
        value: "",
      }));
      setDropdownLabels([...labels, noneJson]); // set the labels and plus one label = none label

      resData?.data?.map((avoidData) => {
        if (avoidData.json_column != "none") {
          setAvoidColumnsList((prevList) => [
            ...prevList,
            avoidData.json_column,
          ]); // add already selected keys to avoid column list
        }
      });
      // for taking source data
        const columnNamesResponse = await get_Columns_name_for_edit();

        if (columnNamesResponse.data) {
          let copySourceData = columnNamesResponse.data;  
          resData?.data?.map((resData) => {

           copySourceData = copySourceData?.map(item =>
                item.column_name === resData.column_name ? { ...item, jsonField: resData.json_column } : item     // update already mapped data to main source data request 
              
              );
          });
          setSourceData(copySourceData) // set Updated Source data
          setSourceData1(copySourceData) // set Updated Source data to dummy_source data also
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }

    }
  };


  // add comumn mapping api
  const handle_mapping_columns = async (mappingData) => {
    try {
      await update_column_mapping(mappingData, success_mapping);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const success_mapping = (res) => {
    if (res.status === "failed") {
      toast.warning(res.message);
    } else {
      formRef.current.reset();
   //   setjsonDataKeysvalues("");
    //  setSourceData([]); // once submit we clear the privious request
      toast.success("Updated Columns Mapped Successfully");
      reloadPageWithDelay(1000); // reload the page
 
    }
  };



  // handle on chnage
  const handleOnChange = (event, index, cValue) => {
    try {
      const copysourceDats = [...sourceData];

      if (cValue != null) {
        if (
          copysourceDats[index].jsonField &&
          copysourceDats[index].jsonField != ""
        ) {
          const copyvoidcolList = [...avoidColumnsList]; // copy the avoidColumnsList
          avoidColumnsList[
            copyvoidcolList.indexOf(sourceData1[index].jsonField) // check the previous  seleccted field index
          ] = cValue.label; // set the new value on exixting value

          copysourceDats[index].jsonField = cValue.label; // added new jsonfield  parameter to json object
          setSourceData(copysourceDats);
        } else {
          copysourceDats[index].jsonField = cValue.label; // added new jsonfield  parameter to json object
          setSourceData(copysourceDats);
          setSourceData1(copysourceDats); // save dummy data
          if (cValue.label != "none") {
            setAvoidColumnsList((prevList) => [...prevList, cValue.label]); // add value to string array
          }
        }
      } else {
        // Use filter to create a new array without the strings to remove
        const filterAvoidCols = avoidColumnsList.filter(
          (column) => !copysourceDats[index].jsonField.includes(column)
        );
        setAvoidColumnsList(filterAvoidCols); // set new aoid columns list
        copysourceDats[index].jsonField = "";
        setSourceData(copysourceDats);
        setSourceData1(copysourceDats); // save dummy data
      }
    } catch (e) {
      console.log(e);
    }
  };

  // handle the mapping Columns request and call the api
  const handleMappingRequest = (e) => {
    e.preventDefault();
    // const isJsonColumnPresent = sourceData?.every(
    //   (item) => item.hasOwnProperty("jsonField") && item.jsonField !== "" // validate mapping json is not empty
    // );
    const filteredSourceData = sourceData?.filter(
      (item) => item.hasOwnProperty("jsonField") && item.jsonField !== ""
    );

    if (filteredSourceData.length > 0 && deviceTypeId != "") {

      mappingrequest.device_id = deviceTypeId;  // add device edited type
      const finalReq = filteredSourceData?.map((sdata) => ({
        // filter source data And takes only mapped columns
        column_num_id: sdata.id,
        json_column: sdata.jsonField,
        device_type_id: deviceTypeId,
      }));

      mappingrequest.data = [...finalReq];
      handle_mapping_columns(mappingrequest); //call the api
    } else {
      toast.warning("Please Select All The Details");
    }
  };

  const handleOk = () => {
    setVisible(false);
  };
  const handleCancel = () => {
    setVisible(false);
  };

  useEffect(() => {
    get_column_names();
    get_DeviceTypes();
  }, []);

  return (
    <>
      <form ref={formRef} onSubmit={handleMappingRequest}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Grid item xs={12}>
              <div>
                <InputLabel id="demo-select-small-label" className="mx-2 mb-2">
                  <b style={{ color: "blue" }}>Device Type</b>
                </InputLabel>
                <Select
                  labelId="demo-select-small-label"
                  id="demo-select-small"
                  defaultValue={deviceTypeId}
                  className="mx-2"
                  // value={age}
                  label="Device Type"
                >
                  <MenuItem value={deviceTypeId} disabled>
                    <em> Select Device Type</em>
                  </MenuItem>
                  {deviceTypes?.map((type) => (
                    <MenuItem
                      value={type.device_type_id}
                      disabled={type.device_type_id !== deviceTypeId}
                    >
                      {type.device_type_name}
                    </MenuItem>
                  ))}
                </Select>
              </div>
            </Grid>

            <MainCard>
              <CardHeader title="Mapping Columns" style={{ color: "Orange" }} />
              <Grid
                container
                spacing={2}
                style={{ flex: 0.7 }}
                alignItems="center"
              >
                {sourceData?.length > 0 &&
                  sourceData?.map((data, index) => (
                    <Grid container item xs={6}>
                      <Grid item sm={3}>
                        <Typography>
                          <b>{data.column_name}</b>
                        </Typography>
                      </Grid>
                      <Grid item sm={1}>
                        <Typography>:</Typography>
                      </Grid>
                      <Grid item sm={3} container justifyContent="flex-end">
                        <Typography>
                          <Autocomplete
                            disablePortal
                            id="combo-box-demo"
                    

                            // value={
                            //   mappedData?.find(
                            //     (mdata) =>
                            //       mdata.column_name === data.column_name
                            //   )
                            //     ? mappedData?.find(
                            //         (mdata) =>
                            //           mdata.column_name === data.column_name
                            //       ).json_column
                            //     : ""
                            // }

                            value={data.hasOwnProperty("jsonField") && data.jsonField != ''? data.jsonField: ''
                            }
                            options={
                              dropdownlables.length > 0
                                ? dropdownlables.filter(
                                    (label) =>
                                      !avoidColumnsList.includes(label.label)
                                  )
                                : dropdownlables
                            } // filter already selected fields
                            sx={{ width: 200 }}
                            onChange={(e, value) =>
                              handleOnChange(e, index, value)
                            }
                            renderInput={(params) => (
                              <TextField {...params} variant="standard" />
                            )}
                          />
                        </Typography>
                      </Grid>
                    </Grid>
                  ))}
              </Grid>
            </MainCard>
            <Grid container xs={12} justifyContent="right">
              <Button
                variant="contained"
                onClick={() => {
                  setVisible(true);
                }}
                className="mx-2"
              >
                Preview
              </Button>
              <Button type="submit" variant="contained">
                Update
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </form>

      <div>
        <Modal
          open={visible}
          title="Mapped Columns"
          onOk={handleOk}
          onCancel={handleCancel}
          footer={[]}
        >
          <div className="mt-4">
            <Grid
              container
              spacing={2}
              style={{ flex: 0.7 }}
              alignItems="center"
            >
              {sourceData &&
                sourceData
                  ?.filter(
                    (item) =>
                      item.hasOwnProperty("jsonField") && item.jsonField !== ""
                  )
                  ?.map((key) => (
                    <Grid container item xs={6}>
                      <Grid item sm={4}>
                        <Typography>
                          <b style={{ color: "green" }}>{key.column_name}</b>
                        </Typography>
                      </Grid>
                      <Grid item sm={2}>
                        <Typography>:</Typography>
                      </Grid>
                      <Grid item sm={3} container justifyContent="flex-end">
                        <Typography>{key.jsonField}</Typography>
                      </Grid>
                    </Grid>
                  ))}
            </Grid>
          </div>
        </Modal>
      </div>
    </>
  );
};

export default EditMapping;
