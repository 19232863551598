// menuItems.js
import Dashboard from "./dashboard";
import support from "./support";
import Configuration from "./configuration";
import "bootstrap/dist/css/bootstrap.min.css";
import { useEffect, useState } from "react";

const MenuItems = () => {
  const configuration = Configuration();
  const dashboard = Dashboard();

  const menuItems = {
    items: [dashboard,configuration],
  };

  return menuItems;
};

export default MenuItems;
