import { useRoutes,useNavigate } from 'react-router-dom';
import React,{useEffect} from 'react';
// project import
import LoginRoutes from './LoginRoutes';
import MainRoutes from './MainRoutes';


// ==============================|| ROUTING RENDER ||============================== //
export default function ThemeRoutes() {
  const isLogin = localStorage.getItem("isLogin") || false;
  const navigate = useNavigate();
  console.log("isLogin:",isLogin);
  // If not logged in, navigate to the login page
  useEffect(() => {
    if (!isLogin) {
      console.log('Redirecting to /login');
      navigate('/login'); // Change '/login' to the actual path of your login page
    }
  }, [isLogin, navigate]);

  // Render routes unconditionally
  const routes = useRoutes([LoginRoutes, MainRoutes]);

  return routes;
}