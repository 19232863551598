import React, { useEffect, useState, useRef } from "react";
import { toast } from "react-toastify";
import { Table, Pagination } from "antd";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import EditIcon from "@mui/icons-material/Edit";
import AddIcon from "@mui/icons-material/Add";
import {
  getMappedJsonColumns,
  getMappedDevices,
  get_location,
  get_Event_Details,
  add_Event_Details,
  deleteEventDetails
} from "../../utils/api";

import {
  InputLabel,
  OutlinedInput,
  Stack,
  Typography,
  Grid,
  Select,
  Tooltip,
  MenuItem,
  Button,
  IconButton,
  Chip,
  FormHelperText,
  Switch,
} from "@mui/material";
import { Tag } from "antd";
import MainCard from "components/MainCard";

function EventDetails(){
  const formRef = useRef();
  const [eventData, seteventData] = useState();
  const [mappedDevices, setMappedDevices] = useState();
  const [jsonColsData, setjsonColsData] = useState([]);
  const [location_list, set_location_list] = useState([]);
  const selectRef = useRef();

  const [eventDetailsReq, seteventDetailsReq] = useState({
    device: "",
    device_name: "i",
    json_column: "i",
    condition: "i",
    values: "",
    location_id: "i",
  });
  const [all_emails, set_all_emails] = useState([]);
  const [email, set_email] = useState("");

  const [enable, set_enable] = useState(true);

  const conditionsData = ["<", ">", "=", "<=", ">="];

  const [edit_status, set_edit_status] = useState(false);
  const [event_id, set_event_id] = useState("");

  const handle_edit = (record) => {
    getMappedDevices(success_mapped_device, {
      location_id: record?.location_id,
    });
    get_MappedJsonColumns(record?.device);
    set_edit_status(true);
    set_event_id(record?.event_id);
    const temp = {
      device: record?.device,
      device_name: record?.device_name,
      json_column: record?.json_column,
      condition: record?.condition,
      values: record?.values,
      location_id: record?.location_id,
    };
    seteventDetailsReq(temp);
    set_enable(record?.active);
    set_all_emails(record?.email);
  };


  const eventDetailColumns = [
    {
      dataIndex: "device",
      title: "Device Id",
      key: "device",
      width: "10%",
    },
    {
      dataIndex: "device_name",
      title: "Device Name",
      key: "device",
      width: "10%",
    },
    {
      dataIndex: "device_type",
      title: "Device Type",
      key: "device_type",
      width: "10%",
    },
    {
      dataIndex: "json_column",
      title: "Parameter",
      key: "json_column",
      width: "10%",
    },
    {
      dataIndex: "condition",
      title: "Condition",
      key: "condition",
      width: "10%",
    },
    {
      dataIndex: "values",
      title: "Value",
      key: "value",
      width: "10%",
    },
    {
      title: "Action",
      dataIndex: "Action",
      key: "Action",
      width: "10%",
      align: "center",
      render: (_, record, index) => {
        return (
          <>
            <Stack direction="row" alignItems="center" justifyContent="center">
              <Tooltip title={"Edit"}>
                <IconButton
                  onClick={() => {
                    handle_edit(record);
                  }}
                >
                  <EditIcon style={{ color: "#F9A825" }} />
                </IconButton>
              </Tooltip>
              <Tooltip title={"Delete event"}>
                <IconButton
                  onClick={() => {
                    const deleteeventReq = { event_id: record?.event_id };
                    deleteevent_Details(deleteeventReq); // delete event
                  }}
                >
                  <DeleteForeverIcon style={{ color: "Red" }} />
                </IconButton>
              </Tooltip>
            </Stack>
          </>
        );
      },
    },
  ];
  //####################### Handle Api's######################################################
  const add_event_details = async (eventData) => {
    try {
      await add_Event_Details(eventData, success_event);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const success_event = (res) => {
    console.log(res);
    if (res.status === "failed") {
      toast.warning(res.message);
    } else {
      formRef.current.reset();
      toast.success(res.message);
      get_eventDetails();
      seteventDetailsReq({
        device: "",
        device_name: "i",
        json_column: "i",
        condition: "i",
        values: "",
        location_id: "i",
      });
      set_enable(true);
      set_edit_status(false);
      setjsonColsData([]);
      setMappedDevices([]);
    }
  };

  const get_eventDetails = async () => {
    try {
      await get_Event_Details(success_event_details);
      await get_location(success_get_locations);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const get_MappedJsonColumns = async (device_Id) => {
    try {
      await getMappedJsonColumns(device_Id, success_mapped_jsonCols);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const success_get_locations = (res) => {
    if (res.status === "failed") {
      toast.warning(res.message);
    } else {
      set_location_list(res.data.data);
    }
  };

  const success_mapped_device = (res) => {
    if (res.status === "failed") {
      toast.warning(res.message);
    } else {
      setMappedDevices(res.data.data);
    }
  };

  const success_event_details = (res) => {
    if (res.status === "failed") {
      toast.warning(res.message);
    } else {
      seteventData(res.data.data);
    }
  };
  const success_mapped_jsonCols = (res) => {
    if (res.status === "failed") {
      toast.warning(res.message);
    } else {
      setjsonColsData(res.data.data);
    }
  };

  // delete selected event
  const deleteevent_Details = async (eventReq) => {
    try {
      await deleteEventDetails(eventReq, success_delete_event_data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const success_delete_event_data = (res) => {
    if (res.status === "failed") {
      toast.warning(res.message);
    } else {
      toast.success(res.message);
      get_eventDetails();
    }
  };

  useEffect(() => {
    get_eventDetails();
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    add_event_details({
      ...eventDetailsReq,
      active: enable,
      ...(edit_status ? { event_id: event_id, update: "update" } : {}),
    });
  };

  return (
    <Typography variant="body2" mx={4}>
      <form ref={formRef} onSubmit={handleSubmit}>
        <Grid container spacing={2} columns={12} sx={{ mb: 4 }}>
          <Grid item xs={12} lg={3}>
            <Stack spacing={1}>
              <InputLabel htmlFor="title">Location</InputLabel>
              <Select
                labelId="demo-select-small-label"
                id="demo-select-small"
                ref={selectRef}
                defaultValue="i"
                value={eventDetailsReq["location_id"]}
                label="location"
                required
                disabled={edit_status}
                onChange={(e) => {
                  seteventDetailsReq({
                    ...eventDetailsReq,
                    location_id: e.target.value,
                    device_name:'i',
                    json_column:'i'
                  });
                  setjsonColsData([]);
                  getMappedDevices(success_mapped_device, {
                    location_id: e.target.value,
                  });
                }}
              >
                <MenuItem value="i" disabled>
                  <em> Select Location</em>
                </MenuItem>
                {location_list?.map((type) => (
                  <MenuItem value={type.location_id}>
                    {type.location_name}
                  </MenuItem>
                ))}
              </Select>
            </Stack>
          </Grid>
          <Grid item xs={12} lg={3}>
            <Stack spacing={1}>
              <InputLabel htmlFor="title">Device</InputLabel>
              <Select
                labelId="demo-select-small-label"
                id="demo-select-small"
                ref={selectRef}
                defaultValue="i"
                value={eventDetailsReq["device_name"]}
                label="device"
                required
                onChange={(e) => {
                  const device_id =
                    mappedDevices?.find(
                      (data) => data.device_name === e?.target?.value
                    )?.device || "";
                  get_MappedJsonColumns(device_id);
                  seteventDetailsReq({
                    ...eventDetailsReq,
                    device_name: e.target.value,
                    device: device_id,
                    json_column:'i'
                  });
                }}
              >
                <MenuItem value="i" disabled>
                  <em> Select Device</em>
                </MenuItem>
                {mappedDevices?.map((type) => (
                  <MenuItem value={type.device_name}>
                    {type.device_name}
                  </MenuItem>
                ))}
              </Select>
            </Stack>
          </Grid>
          <Grid item xs={12} lg={3}>
            <Stack spacing={1}>
              <InputLabel htmlFor="title">Parameter</InputLabel>
              <Select
                labelId="demo-select-small-label"
                id="demo-select-small"
                ref={selectRef}
                defaultValue="i"
                value={eventDetailsReq["json_column"]}
                label="device"
                required
                onChange={(e) =>
                  seteventDetailsReq({
                    ...eventDetailsReq,
                    json_column: e.target.value,
                  })
                }
              >
                <MenuItem value="i" disabled>
                <em> Select Parameter</em>
                </MenuItem>
                {jsonColsData?.map((json) => (
                  <MenuItem value={json.json_column}>
                    {json.json_column}
                  </MenuItem>
                ))}
              </Select>
            </Stack>
          </Grid>

          <Grid item xs={12} lg={3}>
            <Stack spacing={1}>
              <InputLabel htmlFor="title">Condition</InputLabel>
              <Select
                labelId="demo-select-small-label"
                id="demo-select-small"
                value={eventDetailsReq["condition"]}
                label="event Type"
                required
                onChange={
                  (e) =>
                    seteventDetailsReq({
                      ...eventDetailsReq,
                      condition: e.target.value,
                    })
                  // (eventDetailsReq.location_id = e.target.value)
                }
              >
                <MenuItem value="i" disabled>
                  <em> Select Condition</em>
                </MenuItem>
                {conditionsData?.map((item) => (
                  <MenuItem value={item}>{item}</MenuItem>
                ))}
              </Select>
            </Stack>
          </Grid>

          <Grid item xs={12} lg={3}>
            <Stack spacing={1}>
              <InputLabel htmlFor="title">Value</InputLabel>
              <OutlinedInput
                id="title"
                type="number"
                step={1.0}
                name="value"
                placeholder="Enter Value"
                fullWidth
                value={eventDetailsReq["values"]}
                required
                onChange={(e) => {
                  // const floatRegex = /^[-+]?[0-9]*\.?[0-9]+([eE][-+]?[0-9]+)?$/;
                  // if (floatRegex.test(e.target.value)) {
                  seteventDetailsReq({
                    ...eventDetailsReq,
                    values: e.target.value,
                  });
                  // }
                }}
              />
            </Stack>
          </Grid>

          <Grid item xs={12} lg={9}>
            <Stack
              direction="row"
              justifyContent="end"
              alignItems="end"
              sx={{ height: "100%" }}
            >
              <Button
                size="medium"
                type="reset"
                variant="contained"
                color="primary"
                onClick={() => {
                  seteventDetailsReq({
                    device: "",
                    device_name: "i",
                    json_column: "i",
                    condition: "i",
                    values: "",
                    location_id: "i",
                  });
                  set_enable(true);
                }}
                className="mx-2"
              >
                Reset
              </Button>
              <Button
                size="medium"
                type="submit"
                variant="contained"
                color="primary"
                disabled={ 
                  eventDetailsReq["location_id"] === "i" ||
                  eventDetailsReq["condition"] === "i" ||
                  eventDetailsReq["device"] === "i" ||
                  eventDetailsReq["json_column"] === "i" ||
                  !eventDetailsReq["values"]
                }
              >
                {!edit_status ? "Submit" : "Update"}
              </Button>
            </Stack>
          </Grid>
        </Grid>
      </form>
      <div className="mt-2">
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Table
              dataSource={eventData}
              columns={eventDetailColumns}
              // scroll={{ x: 940, y: 410 }}
              size="small"
              bordered
              pagination={true}
            />
          </Grid>
        </Grid>
      </div>
    </Typography>
  );
}

export default EventDetails;
