// assets
import { ControlOutlined } from "@ant-design/icons";
import SchemaOutlinedIcon from "@mui/icons-material/SchemaOutlined";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import SettingsInputSvideoOutlinedIcon from "@mui/icons-material/SettingsInputSvideoOutlined";
import PermDataSettingIcon from "@mui/icons-material/PermDataSetting";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import NotificationImportantOutlinedIcon from "@mui/icons-material/NotificationImportantOutlined";
import React, { useState, useEffect } from "react";

// icons
const icons = {
  SchemaOutlinedIcon,
  SettingsInputSvideoOutlinedIcon,
  PersonOutlineOutlinedIcon,
  ControlOutlined,
  NotificationImportantOutlinedIcon,
  PermDataSettingIcon,
  SettingsOutlinedIcon,
};

// ==============================|| MENU ITEMS ||============================== //

function Configuration() {
  return {
    id: "group-dashboard",
    title: "Mapping",
    type: "group",
    children: [
      {
        id: "Mapping Details",
        title: "Mapping Details",
        type: "item",
        icon: icons.SchemaOutlinedIcon,
        url: "/mapping-details",
        breadcrumbs: true,
      },

      {
        id: "configurations",
        title: "Configurations",
        type: "collapse",
        icon: icons.PermDataSettingIcon,
        breadcrumbs: true,
        children: [
          // {
          //   id: "AddDeviceType",
          //   title: "Add DeviceType",
          //   type: "item",
          //   icon: icons.SettingsOutlinedIcon,
          //   url: "/add-deviceType",
          //   breadcrumbs: true,
          // },
          // {
          //   id: "AddLocation",
          //   title: "Add Location",
          //   type: "item",
          //   icon: icons.SettingsOutlinedIcon,
          //   url: "/add-deviceLocation",
          //   breadcrumbs: true,
          // },
          {
            id: "AddDeviceDetails",
            title: "Devices",
            type: "item",
            icon: icons.SettingsOutlinedIcon,
            url: "/add-deviceDetails",
            breadcrumbs: true,
          },
          {
            id: "alertDetails",
            title: "Alerts - Reports",
            type: "item",
            icon: icons.NotificationImportantOutlinedIcon,
            url: "/alerts-reports",
            breadcrumbs: true,
          },
        ],
      },
    ],
  };
}

export default Configuration;
