import { createSlice } from "@reduxjs/toolkit";

const forgotPasswordSlice = createSlice({
  name: "forgotPasswordShow",
  initialState: false,
  reducers: {
    setforgotPasswordShow: (state, action) => {
      return action.payload;
    },
  },
});

export const { setforgotPasswordShow } = forgotPasswordSlice.actions;
export default forgotPasswordSlice.reducer;
